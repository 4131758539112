import * as Sentry from "@sentry/nextjs";
import { getCookie } from "@src/utils/cookies";
import hideLettersOfString from "@src/utils/format/hideLettersOfString";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import {
  expiresState,
  isSessionActiveState,
  loggedInUserUriState,
  refreshLoadingState
} from "@states/auth.state";
import type { Router } from "next/router";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import type { SetterOrUpdater } from "recoil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import type { ErrorHandlerFunc } from "./error-handling/useApiErrorHandler";
import { useApiErrorHandler } from "./error-handling/useApiErrorHandler";

export const useRefreshSession = () => {
  const { push, pathname } = useRouter();

  const timerRef = useRef<NodeJS.Timer | null>(null);

  const isSessionActive = useRecoilValue(isSessionActiveState);

  const setIsRefreshLoading = useSetRecoilState(refreshLoadingState);

  const [handleApiError] = useApiErrorHandler();

  const [realUserUri, setRealUserUri] = useRecoilState(loggedInUserUriState);

  const [expires, setExpires] = useRecoilState(expiresState);

  useEffect(() => {
    timerRef.current && clearInterval(timerRef.current);

    const interval = expires
      ? new Date(expires).getTime() - Date.now() - 1000 * 60
      : 1000 * 60 * 3;

    if (!isSessionActive) return undefined;

    timerRef.current = setInterval(
      refreshSession(
        setRealUserUri,
        setExpires,
        setIsRefreshLoading,
        push,
        pathname,
        handleApiError
      ),
      interval
    );

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, [
    setRealUserUri,
    setExpires,
    isSessionActive,
    setIsRefreshLoading,
    push,
    expires,
    pathname
  ]);

  useEffect(() => {
    if (!realUserUri && isSessionActive) {
      refreshSession(
        setRealUserUri,
        setExpires,
        setIsRefreshLoading,
        push,
        pathname,
        handleApiError
      )();
    }
  }, [
    realUserUri,
    isSessionActive,
    setRealUserUri,
    setExpires,
    setIsRefreshLoading,
    push,
    pathname
  ]);
};

const refreshSession =
  (
    setUserUri: SetterOrUpdater<string>,
    setExpires: SetterOrUpdater<string>,
    setIsRefreshLoading: SetterOrUpdater<boolean>,
    push: Router["push"],
    pathname: string,
    handleApiError: ErrorHandlerFunc
  ) =>
  async () => {
    try {
      setIsRefreshLoading(true);

      smavestoCore.debugLog("info", "auth refresh", window?.location);

      const cookie = getCookie("XSRF-TOKEN", window.document.cookie);

      const allowedPaths = [
        "/login",
        "/slice-simulator",
        "/maintenance",
        "/change-email",
        "/registration",
        "/registration/activate",
        "/onboarding/submit",
        "/safe-logout"
      ];

      /* Without XSRF it makes no sense to refresh this session */
      if (!cookie && !allowedPaths.some(a => pathname.includes(a))) {
        smavestoCore.debugLog(
          "info",
          "redirect to login because of missing xsrf cookie",
          window?.location
        );
        push("/safe-logout");
        return;
      }

      const data = await smavestoCore.services.auth.refresh();

      if (isApiErrorResponse(data)) {
        if (!allowedPaths.some(a => pathname.includes(a))) {
          smavestoCore.debugLog(
            "info",
            "redirect to login because of invalid session",
            window?.location
          );
          push("/safe-logout");
        }
      } else {
        setUserUri(data.userIdent);
        Sentry.setUser({ id: hideLettersOfString(data.userIdent, 2) });
        setExpires(data.expires);
      }
    } catch (err: any) {
      if (
        err.response?.status === 401 ||
        (err.key && err.key === "user.not.authorized")
      ) {
        push("/login?loggedOut=true");
      }
      smavestoCore.debugLog("error", err, "error");
    } finally {
      setIsRefreshLoading(false);
    }
  };
