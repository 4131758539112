import cx from "classnames";
import React, { useEffect, useState } from "react";

import type { ILayoutBaseProps } from "@src/interfaces/jsx.interface";
import { localInvestmentSettingsState } from "@states/investment-settings.state";
import type OnboardingChapter from "smavesto.core/lib/types/dto/onboarding/OnboardingChapter";

import { useAsyncSelector } from "@src/hooks/useAsyncSelector";
import LightModeSwitch from "../../lightmode/LightModeSwitch";
import SecondLayerWrapper from "../../lightmode/SecondLayerWrapper";
import OnboardingMobileMenu from "../../navigation/OnboardingMobileMenu/OnboardingMobileMenu";
import OnboardingNavigation from "../../navigation/OnboardingNavigation/OnboardingNavigation";
import { FooterPublic } from "../../page/footer/FooterPublic";
import styles from "../OnboardingLayout/styles.module.less";
import BasicPageLayout from "../layout/BasicPageLayout";

export interface PublicOnboardingLayoutProps extends ILayoutBaseProps {
  disableFooter?: boolean;
}

const PublicOnboardingLayout: React.FunctionComponent<
  PublicOnboardingLayoutProps
> = props => {
  const [completedChapters, setCompletedChapters] = useState<
    OnboardingChapter[]
  >([]);

  const disabledChapters: OnboardingChapter[] = [
    "ZUGANGSDATEN",
    "PREFERECENCES",
    "PERSOENLICHE_ANGABEN",
    "FINANZIELLE_LAGE",
    "KENNTNISSE",
    "BANKVERBINDUNG",
    "SUMMARY",
    "SUMMARY2"
  ];

  const hiddenChapters: OnboardingChapter[] = [
    "PREFERECENCES",
    "PERSOENLICHE_ANGABEN",
    "FINANZIELLE_LAGE",
    "KENNTNISSE",
    "BANKVERBINDUNG",
    "SUMMARY",
    "SUMMARY2"
  ];

  const [, localInvestmentSettings] = useAsyncSelector(
    localInvestmentSettingsState
  );

  useEffect(() => {
    if (
      localInvestmentSettings &&
      !completedChapters.includes("ANLAGEWUNSCH")
    ) {
      completedChapters.push("ANLAGEWUNSCH");
      setCompletedChapters([...new Set(completedChapters)]);
      const idx = disabledChapters.indexOf("ZUGANGSDATEN");
      if (idx >= 0) {
        disabledChapters.splice(idx, 1);
      }
    }
  }, [completedChapters, disabledChapters, localInvestmentSettings]);

  return (
    <BasicPageLayout>
      <SecondLayerWrapper paddingTop>
        <div className={styles["main-layout-container"]}>
          <div className={styles["bar-wrapper"]}>
            <OnboardingNavigation
              completedChapters={completedChapters}
              disabledChapters={disabledChapters}
              hiddenChapters={hiddenChapters}
            />
          </div>

          <div className={styles["mobile-bar-wrapper"]}>
            <OnboardingMobileMenu
              publicRoute
              completedChapters={completedChapters}
              disabledChapters={disabledChapters}
              hiddenChapters={hiddenChapters}
            />
          </div>

          <div
            className={cx(
              styles["layout-container"],
              styles["wrapper-container"],
              "g-layout-container--master"
            )}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <LightModeSwitch />
            </div>

            <props.mainComp
              router={props.routeProps}
              pageProps={props.pageProps}
            />
          </div>
        </div>
      </SecondLayerWrapper>
      {props?.disableFooter ? null : (
        <div className={cx(styles["layout-footer"], "g-layout-footer--master")}>
          <FooterPublic />
        </div>
      )}
    </BasicPageLayout>
  );
};

export default PublicOnboardingLayout;
