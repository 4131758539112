import { Col, Row } from "antd";
import { useRouter } from "next/router";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import { isStartAmountLargeState } from "@src/state/risk-yield.state";
import theme from "@styles/theme";

import { loggedInUserUriState } from "@src/state/auth.state";
import {
  onboardingByEnvironmentState,
  onboardingUserCompletedSummaryPageState
} from "@src/state/onboarding.state";
import useThemedModal from "@src/theme/hook/useThemedModal";
import removeQueryString from "@src/utils/query-params/removeQueryString";
import { bundledLoadingState } from "@src/utils/recoil/bundledLoadingState";
import onboardingStepsRegularDepot from "@src/workflows/onboarding-default/onboardingStepsRegularDepot";
import type WorkflowStep from "smavesto.core/lib/types/dto/onboarding/WorkFlowStep";
import getAllPreviousSteps from "smavesto.core/lib/utils/onboarding/getAllPreviousSteps";
import isLegitimated from "smavesto.core/lib/utils/typeguards/isLegitimated";
import InfoCollapseBox from "../../dashboard/InfoCollapseBox";
import ContactWidget from "../../widgets/ContactWidget";
import { MenuButton } from "./MenuButton";
import { OnboardingUnderageNavigationSkeleton } from "./OnboardingUnderageNavigationSkeleton";
import styles from "./style.module.less";

interface OnboardingNavigationProps {
  completedChapters: string[];
  disabledChapters: string[];
  hiddenChapters: string[];
}

const OnboardingNavigation: React.FunctionComponent<
  OnboardingNavigationProps
> = ({ completedChapters }) => {
  const { push, route, query } = useRouter();

  const { contextHolder, openModal } = useThemedModal(true);

  const [userUriLoadingState, userUri] = useAsyncSelector(loggedInUserUriState);

  const [, onboardingUserCompletedSummary] = useAsyncSelector(
    onboardingUserCompletedSummaryPageState
  );

  const userIsLoggedIn = !!userUri;

  const [onboardingLoadingState, onboarding] = useAsyncSelector(
    onboardingByEnvironmentState("loggedInUser")
  );

  const isLoading =
    bundledLoadingState([userUriLoadingState, onboardingLoadingState]) ===
    "loading";

  // We disable all personal data steps of the guardian if this user is allready legitimated
  const userIsLegitimated = onboarding ? isLegitimated(onboarding) : false;

  const [, isStartAmountLarge] = useAsyncSelector(
    isStartAmountLargeState("loggedInUser")
  );

  const legitimatedUserDisabledSteps = ["PERSOENLICHE_ANGABEN"];

  const loggedInUserDisabledSteps = ["ZUGANGSDATEN"];

  const isAdditionalDepot = query.depotType === "additional";

  const publicRoute = isAdditionalDepot || !userIsLoggedIn;

  const isActiveStep = (step: WorkflowStep) => {
    return (
      route === removeQueryString(step.to) ||
      step.subSteps?.some(step => route === removeQueryString(step.to))
    );
  };

  const isCompleted = (chapterName: string): boolean => {
    /* Workaround for the missing chapter at DSER */
    if (chapterName === "SUMMARY" && onboardingUserCompletedSummary === true)
      return true;

    return completedChapters.includes(chapterName);
  };

  const isDisabled = (step: WorkflowStep): boolean => {
    // We ignore this if the user has this step currently open
    if (isActiveStep(step)) return false;

    const previousSteps = getAllPreviousSteps(step.chapterName, [
      {
        order: 1,
        items: onboardingStepsRegularDepot(publicRoute, isAdditionalDepot)
      }
    ]);

    if (previousSteps.some(previousStep => !isCompleted(previousStep)))
      return true;

    return disabledSteps.includes(step.chapterName);
  };

  const isDisabledForLoggedInUsers = (step: WorkflowStep): boolean => {
    return (
      userIsLoggedIn && loggedInUserDisabledSteps.includes(step.chapterName)
    );
  };

  const disabledSteps = userIsLegitimated ? legitimatedUserDisabledSteps : [];

  if (isLoading) return <OnboardingUnderageNavigationSkeleton />;

  return (
    <div className={styles["navigation-wrapper"]}>
      <Row>
        <Col span={24}>
          <div
            style={{
              padding: 25,
              paddingTop: 0,
              borderRight: "1px solid rgba(255,255,255,0.1)"
            }}
          >
            {onboardingStepsRegularDepot(publicRoute, isAdditionalDepot).map(
              (step, index) => (
                <div
                  style={{ marginTop: index === 0 ? 4 : 20 }}
                  key={step.order}
                >
                  <MenuButton
                    style={{
                      boxShadow: isActiveStep(step)
                        ? `0 0 10px 5px ${theme.primary}`
                        : "none"
                    }}
                    disabled={isDisabled(step)}
                    title={step.text}
                    order={(index + 1).toString()}
                    complete={isCompleted(step.chapterName)}
                    hidden={false}
                    onClick={() =>
                      isDisabledForLoggedInUsers(step)
                        ? openModal("info", {
                            title: "Hinweis",
                            content: (
                              <>
                                Sie haben sich bereits bei Smavesto registriert
                                und können Ihre Zugangsdaten nach Ihrer
                                Legitimation in Ihrem Dashboard anpassen.
                              </>
                            )
                          })
                        : push({ pathname: step.to, query })
                    }
                  />
                  {step.subSteps?.map(subStep => (
                    <div style={{ marginTop: 4 }} key={subStep.order}>
                      <MenuButton
                        hideOrderCircle
                        style={{
                          boxShadow: isActiveStep(subStep)
                            ? `0 0 10px 3px ${theme.primary}`
                            : "none"
                        }}
                        title={subStep.text}
                        order={subStep.order.toString() || index.toString()}
                        complete={isCompleted(subStep.chapterName)}
                        disabled={isDisabled(subStep)}
                        hidden={false}
                        onClick={() => push({ pathname: subStep.to, query })}
                      />
                    </div>
                  ))}
                </div>
              )
            )}

            {route === "/onboarding/summary2" ? (
              <InfoCollapseBox
                title={
                  isStartAmountLarge
                    ? "Überweisung Ihres Startbetrags"
                    : "Lastschrift Ihres Startbetrags"
                }
              >
                {isStartAmountLarge
                  ? "Nach Ihrer Legitimation richtet unser Partner, die Baader Bank, Ihr Depot für Sie ein. Im Anschluss erhalten Sie eine E-Mail mit den Überweisungsdaten für Ihre Starteinzahlung."
                  : "Nach Ihrer Legitimation veranlassen wir die Lastschrift Ihrer gewünschten Starteinzahlung für Sie."}
              </InfoCollapseBox>
            ) : (
              <ContactWidget
                title="Haben Sie Fragen?"
                content="Unser Relationship Manager Tim Bergmann und sein Team sind für Sie da."
              />
            )}
          </div>
        </Col>
      </Row>
      {contextHolder}
    </div>
  );
};

export default OnboardingNavigation;
