import ReallocationTooltip from "@src/components/layout/tooltips/ReallocationTooltip";

const onboardingUnderageLabels = {
  dashboard: {
    balance: {
      title: "Aktuelles Guthaben",
      info: "Dieser Betrag zeigt den Wert der Geldanlage Ihres Kindes an. I.d.R. handelt es sich hierbei um den Wert vom Vortag."
    },
    payout: {
      equalsAllAssetValue: `Sie möchten das komplette Vermögen Ihres Kindes auszahlen. Um die Abwicklung von Gebühren und Transaktionen auch zukünftig gewährleisten zu können, möchten wir Sie bitten, stets einen kleinen Betrag auf dem Verrechnungskonto Ihres Kindes zu lassen.\n\nSie möchten den Vermögensverwaltungsvertrag mit uns kündigen? Den dazugehörigen Bereich finden Sie in dem „Profil“ Ihres Kindes. Im Zuge der Kündigung werden wir das Vermögen Ihres Kindes automatisch auszahlen – hierzu ist keine Eingabe eines Auszahlungsbetrags nötig.`
    }
  },
  financialSituation: {
    title: "Anlageziele und finanzielle Verhältnisse des Kindes",
    fields: {
      strategy: {
        title: "Die Anlageziele für Ihr Kind *"
      },
      investmentPeriod: {
        title: "Anlagehorizont",
        label: "Der gewünschte Anlagehorizont für Ihr Kind *",
        text: "Sie können bei Smavesto jederzeit über Ihr Geld verfügen. Es gibt auch keine Vertragslaufzeit. Smavesto ist jedoch gesetzlich verpflichtet Sie zu fragen, für wie lange Sie heute planen Ihr Geld für sich arbeiten zu lassen."
      },

      lossBearingCapacity: {
        title: "Die finanzielle Verlusttragfähigkeit Ihres Kindes",
        label:
          "Mein Kind kann Verluste tragen (bis zum vollständigen Verlust des eingesetzten Kapitals).*",
        text: "Verlustfähigkeit bezeichnet die Fähigkeit, etwaige Verluste wirtschaftlich tragen zu können, ohne Einbußen im täglichen Leben zu erfahren."
      },

      monthlyIncome: {
        title: "Wie viel Geld steht für Ihr Kind monatlich zur Verfügung",
        text: ""
      },

      liquidCapital: {
        title:
          "Das liquide Vermögen (z.B. Bankguthaben, Wertpapiere, usw.) Ihres Kindes beträgt in EUR",
        text: ""
      }
    },
    hint: {
      title: "Hinweis",
      description:
        "Wenn Sie nur geringe bzw. keine finanziellen Verluste des eingesetzten Kapitals tragen können, dann ist die hier gewählte Anlageform für Sie nicht geeignet. Über alternative Anlageformen informiert Sie gerne Ihre Bank oder Sparkasse."
    }
  },
  investmentSettings: {
    rules: {
      strategy: [
        { required: true, message: "Bitte wählen Sie Ihr Anlageziel aus." }
      ],

      investmentPeriod: [
        {
          required: true,
          message: "Bitte wählen Sie Ihren gewünschten Anlagehorizont aus."
        }
      ],

      lossBearingCapacity: [
        {
          required: true,
          transform: (value: boolean) => value || undefined,
          type: "boolean" as const,
          message: "Bitte wählen Sie Ihre finanzielle Verlusttragfähigkeit aus."
        }
      ],

      monthlyIncome: [
        {
          required: true,
          message:
            "Bitte geben Sie den monatlich zur Verfügung stehenden Betrag (netto) abzüglich regelmäßiger Verpflichtungen ein."
        }
      ],
      liquidCapital: [
        {
          required: true,
          message: "Bitte geben Sie Ihr liquides Vermögen ein."
        }
      ],

      customMonthlyAmount: [
        { required: true, message: "Dieses Feld ist ein Pflichtfeld." }
      ],

      customStartAmount: [
        { required: true, message: "Dieses Feld ist ein Pflichtfeld." }
      ]
    },
    strategies: [
      { label: "Altersvorsorge", value: "NO_RISK" },

      {
        label: "Allgemeine Vermögensbildung und -optimierung",
        value: "MEDIUM_RISK"
      },

      {
        label: "Überproportionale Teilnahme an Kursveränderungen",
        value: "HIGH_RISK"
      }
    ],

    investmentPeriods: [
      { label: "Kurzfristig bis 3 Jahre", value: "SHORT_TERM" },

      { label: "Mittelfristig 3 bis 5 Jahre", value: "MEDIUM_TERM" },

      { label: "Langfristig ab 5 Jahre", value: "LONG_TERM" }
    ],

    investmentRiskMarks: {
      0: {
        label: "Sicherheitsorientiert",
        value: "Sicherheitsorientiert",
        style: { left: "9%" }
      },
      7: {
        label: "Konservativ",
        value: "Konservativ",
        style: { left: "28.5%" }
      },
      15: {
        label: "Gewinnorientiert",
        value: "Gewinnorientiert",
        style: { left: "49.5%" }
      },
      25: {
        label: "Risikobewusst",
        value: "Risikobewusst",
        style: { left: "74.5%" }
      },
      35: { label: "Spekulativ", value: "Spekulativ", style: { left: "94%" } },
      40: { label: " ", value: "dummy" }
    },

    lossBearingCapacities: [
      {
        label:
          "Mein Kind kann keine bzw. nur geringe Verluste des eingesetzten Kapitals tragen.",
        value: "NO_LOSS"
      },
      {
        label:
          "Mein Kind kann Verluste tragen (bis zum vollständigen Verlust des eingesetzten Kapitals).",
        value: "SOME_LOSS"
      }
    ],
    startAmounts: [
      { label: "Kein Startbetrag", value: 0 },
      { label: "1.000 €", value: 1000 },
      { label: "5.000 €", value: 5000 },
      { label: "10.000 €", value: 10000 },
      { label: "Individuellen Betrag wählen", value: "individual" as const }
    ],

    monthlyDeposits: [
      { label: "Keine mtl. Einzahlung", value: 0 },
      { label: "50 €", value: 50 },
      { label: "100 €", value: 100 },
      { label: "200 €", value: 200 },
      { label: "Individuellen Betrag wählen", value: "individual" as const }
    ],

    labelInfoTooltips: {
      strategy: {
        title: "Die Anlageziele für Ihr Kind *"
      },

      investmentPeriod: {
        title: "Anlagehorizont",
        text: "Sie können bei Smavesto jederzeit über das Geld Ihres Kindes verfügen. Es gibt auch keine Vertragslaufzeit. Smavesto ist jedoch gesetzlich verpflichtet Sie zu fragen, für wie lange Sie heute planen das Geld Ihres Kindes für sich arbeiten zu lassen."
      },

      lossBearingCapacity: {
        title: "Die finanzielle Verlusttragfähigkeit",
        text: "Verlusttragfähigkeit bezeichnet die Fähigkeit, etwaige Verluste wirtschaftlich tragen zu können, ohne Einbußen im täglichen Leben zu erfahren."
      },

      monthlyIncome: {
        label: "Wie viel Geld steht für Ihr Kind monatlich zur Verfügung *",
        title: "Monatlich zur Verfügung",
        text: "Damit ist der Betrag gemeint, der für Ihr Kind nach Abzug der regelmäßig anfallenden Fixkosten zur Verfügung steht."
      },

      liquidCapital: {
        title: "Liquides Vermögen (z.B. Bankguthaben, Wertpapiere, usw.)",
        label:
          "Das liquide Vermögen (z.B. Bankguthaben, Wertpapiere, usw.) Ihres Kindes beträgt in EUR *",
        text: "Zum liquiden Vermögen zählen Geldanlagen mit relativ schneller Verfügbarkeit. Dies sind unter anderem Giro-, Tages- oder Festgeldkonten, Spareinlagen sowie Wertpapieranlagen."
      },

      startAmount: {
        title: "Startbetrag",
        text: "Legen Sie hier fest, mit welchem Betrag Sie die Geldanlage für Ihr Kind starten möchten."
      },

      monthlyAmount: {
        title: "mtl. Einzahlungen",
        text: "Hier bestimmen Sie den persönlichen Sparplan für Ihr Kind. Schon ab einer monatlichen Einzahlung von 50 Euro ist Ihr Kind dabei."
      }
    },
    warningModalSettings: {
      strategy: { width: 870, centered: true },

      investmentPeriod: {
        okText: "Verstanden",
        title:
          "Hinweis: Diese Art der Geldanlage eignet sich nur für einen Anlagehorizont von mehr als 3 Jahren.",
        content:
          "Diese Art der Geldanlage eignet sich nur für einen Anlagehorizont von mehr als 3 Jahren. Sie wünschen einen Anlagehorizont von weniger als 3 Jahren? Wir bei Smavesto glauben, dass durch ein cleveres Management die Chancen an den Kapitalmärkten die damit verbundenen Risiken langfristig überwiegen. Ein Zeitraum von weniger als 3 Jahren ist nach unserer Ansicht für eine Vermögensverwaltung nicht geeignet. Wenn Sie eine Geldanlage mit einem kurzen Anlagehorizont wünschen, informiert Sie gern Ihre Sparkasse oder jedes andere Geldinstitut."
      },

      lossBearingCapacity: {
        okText: "Verstanden",
        title: "Diese Art der Geldanlage ist für Sie nicht geeignet.",
        info: "Hinweis: Wenn Ihr Kind nur geringe bzw. keine finanziellen Verluste des eingesetzten Kapitals tragen kann, dann ist die hier gewählte Anlageform für Ihr Kind nicht geeignet. Über alternative Anlageformen informiert gerne Ihre Bank oder Sparkasse.",
        content:
          'Wenn Sie nur geringe bzw. keine finanziellen Verluste des eingesetzten Kapitals tragen können, ist eine risikobehaftete Anlage in Wertpapiere für Sie wahrscheinlich nicht das Richtige. Womöglich sind "risikolose" Produkte wie z.B. Tagesgeld für Sie interessanter. Hierüber informiert Sie gerne z.B. Ihre Sparkasse.'
      },

      investmentRisk: {
        okText: "Verstanden",
        title:
          "Hinweis: Diese Art der Geldanlage scheint nicht das Richtige für Ihr Kind zu sein."
      },

      monthlyIncome: { width: 870, centered: true },

      liquidCapital: { width: 870, centered: true }
    },

    strategyModalWarning: `Das von Ihnen gewählte Anlageziel ist nur bei einer höheren, als von Ihnen vorgegebenen Risikobereitschaft zu erreichen.`,
    monthlyIncomeModalWarning: `Der von Ihnen ausgewählte monatliche Sparbetrag ist höher als der monatlich zur Verfügung stehende Betrag für Ihr Kind. Sie können den monatliche Einzahlungen entsprechend anpassen und danach den Prozess weiter fortsetzen.`,
    liquidCapitalModalWarning: `Der von Ihnen gewählte Startbetrag ist höher als das liquides Vermögen Ihres Kindes. Sie können den Anlagebetrag entsprechend anpassen und danach den Prozess fortsetzen.`,
    incomeCostsValidationMessage: {
      liquidCapital: {
        range:
          "Das liquide Vermögen muss mindestens _betrag_ (126% vom Startbetrag) betragen.",
        required: "Bitte geben Sie das liquide Vermögen ein."
      },
      monthlyIncome: {
        range:
          "Der monatlich zur Verfügung stehende Betrag muss mindestens so hoch sein wie die monatlichen Einzahlungen.",
        required:
          "Bitte geben Sie den monatlich zur Verfügung stehenden Betrag abzüglich regelmäßiger Verpflichtungen ein."
      },
      startAmount: {
        minValue:
          "Ihr Startbetrag muss mindestens 1.000€ betragen, Sie können auch mit keinem Startbetrag beginnen.",
        equalZero:
          "Ihr Startbetrag und die monatliche Einzahlung dürfen nicht gleichzeitig 0€ betragen.",
        required: "Dieses Feld ist ein Pflichtfeld."
      },
      monthlyAmount: {
        minValue:
          "Die kleinste mtl. Einzahlung liegt bei 50€ im Monat. Wenn Sie keine mtl. Einzahlung wünschen wählen Sie bitte “keine monatliche Einzahlung”.",
        equalZero:
          "Ihr Startbetrag und die monatliche Einzahlung dürfen nicht gleichzeitig 0€ betragen.",
        required: "Dieses Feld ist ein Pflichtfeld."
      }
    }
  },
  personalData: {
    politicallyExposed: {
      title:
        "Sie haben angegeben, dass Ihr Kind eine politisch exponierte Person ist.",
      text: `Politisch exponierte Personen sind Menschen, die ein hochrangiges öffentliches Amt auf internationaler, europäischer oder nationaler Ebene ausüben oder ausgeübt haben. Ebenso umfasst sind Menschen, die ein öffentliches Amt unterhalb der nationalen Ebene innehaben oder -hatten, wenn die politische Bedeutung vergleichbar ist. Miterfasst sind Ehepartner, Eltern, Kinder und deren Ehepartner sowie nahestehende Personen. Für sie gelten strengere gesetzliche Regelungen im Hinblick auf Geldwäsche.`
    }
  },
  investmentRisk: {
    text: "Wie wollen Sie für Ihr Kind anlegen?",
    onlySustainableCompanies: [
      { label: "Klassisch", value: false },
      { label: "Nachhaltig", value: true }
    ],
    noForeignCurrency: {
      label: "Ich möchte für mein Kind auch in Fremdwährung investieren",
      info: "Für eine optimale Zusammenstellung des Portfolios Ihres Kindes berücksichtigt Smavesto nicht nur verschiedene Anlageklassen, sondern auch unterschiedliche Währungen. Eine Investition in eine Fremdwährung birgt für Ihr Kind die Chance, von Kursveränderungen beim Währungstausch zu profitieren, aber auch das Risiko, ggf. Verluste zu verzeichnen."
    },
    noEmerginMarkets: {
      label:
        "Ich möchte für mein Kind auch in Emerging Markets (Schwellenländer) investieren",
      info: "Bei Emerging Markets handelt es sich um sogenannte Schwellenländer, die sich erst auf dem Weg zu einer modernen Volkswirtschaft befinden. Diese Länder oder Regionen verfügen bisher noch nicht über einen voll entwickelten Markt – aus diesem Grund findet sich hier häufig ein hohes Wirtschaftswachstum, von dem Sie profitieren können. Als Beispiele gelten etwa die Volksrepublik China oder Indien, aber auch kleinere Staaten Europas wie Bulgarien.\nEine Investition in Emerging Markets birgt für Sie die Chance, von der aufstrebenden Wirtschaft dieser Staaten zu profitieren. Es bestehen jedoch auch höhere Risiken (u.a. Währungsrisiken, politische Risiken und ggf. eine eingeschränkte Markttransparenz), die zu Verlusten des eingesetzten Kapitals führen können."
    },
    investInGold: {
      label: "Ich möchte für mein Kind auch 10% fest in Gold investieren",
      info: "Gold zählt zur Anlageklasse der Rohstoffe. Die Anlage erfolgt indirekt über einen sog. ETC (Exchange Traded Commodities), d.h. der Wert wird über den Marktwert des jeweiligen Basiswerts bestimmt. Es wird kein Gold physisch in ein Schließfach auf Ihren Namen gekauft. Bei der Auswahl von Gold investierten wir für Sie fest 10% des verwalteten Vermögens in diese Anlageklasse. Marktentwicklungsbedingte Über- / Unterschreitungen der Anlagestrategie werden bis 5%-Punkten des Gesamtvolumens toleriert. Eine Anpassung auf 10% erfolgt im Rahmen der nächsten Reallokation.\nIm Rahmen der Investition in diese Anlageklasse kann es zu Wertschwankungen kommen."
    },
    investInCrypto: {
      label:
        "Ich möchte für mein Kind auch bis zu 10% in Kryptowährungen investieren",
      info: "Kryptowährungen zählen zur Anlageklasse der Währungen. Die Anlage erfolgt über die Abbildung des bestimmten Basiswerts für die jeweilige Kryptowährung. Die Preise für Kryptowährungen unterliegen oftmals einer großen Schwankungsbreite. Die Einflussfaktoren auf diese Preise sind sehr komplex und hängen von der Ausgestaltung der jeweiligen Währung ab. Da es sich um eine digitale Währung handelt, ist diese stark von IT-Systemen abhängig. Die Investition ist daher ausschließlich für Anleger mit einem spekulativen Risikoprofil zulässig. Dabei kann maximal bis zu 10% des verwalteten Vermögens in Kryptowährungen, z.B. in Bitcoin investiert werden. Marktentwicklungsbedingte Über- / Unterschreitungen der Anlagestrategie werden bis 5%-Punkten des Gesamtvolumens toleriert. Eine Anpassung erfolgt im Rahmen der nächsten Reallokation."
    },
    infoAi:
      "Smavesto setzt die Geldanlage Ihres Kindes unter Zuhilfenahme künstlicher Intelligenz immer wieder neu optimal für Sie zusammen. Je weniger Einschränkungen Sie vornehmen, desto größer ist der Handlungsspielraum von Smavesto.",
    goodToKnowInfo:
      "Sie können den Sparbetrag für Ihr Kind jederzeit erhöhen, verringern oder aussetzen.\nAktionscodes können im letzten Schritt des Onboardings eingelöst werden.",
    infoAboutChanges:
      "Kein Problem, Sie können das Anlagerisiko Ihres Kindes jederzeit anpassen.",
    onlySustainableCompaniesInfo: {
      title: "Unsere Nachhaltigkeitsstrategie",
      text: "Wenn Sie sich für die nachhaltige Variante entscheiden, bewerten wir alle Investitionen in unserem Vermögensmanagement nach sogenannten ESG-Kriterien. Erfahren Sie mehr in unserer ",
      link: "https://smavesto.de/files/32/Nachhaltigkeitsbezogene_Offenlegung_der_Smavesto_GmbH_06_2024_1.pdf"
    },
    modalOptions: {
      crypto: {
        okText: "Zu meinen Kenntnissen",
        cancelText: "Abbrechen",
        title: "Kenntnisse aktualisieren",
        content:
          "Bitte aktualisieren Sie zunächst Ihre Kenntnisse und Erfahrungen im Bereich Kryptowährungen."
      },
      cryptoRisk: {
        okText: "Verstanden",
        cancelText: "Abbrechen",
        title: "Hinweis",
        content:
          "Investitionen in Kryptowährungen (Zertifikate), werden nur unterstützt, wenn Sie spekulativ als Anlagerisiko wählen."
      },
      investmentSettings: {
        okText: "Schließen",
        title: "Hinweis",
        content:
          "Investitionen in Gold (Zertifikate), Kryptowährungen (Zertifikate) und Emerging Markets werden nur unterstützt wenn Sie der Geldanlage in Fremdwährungen zustimmen. Zudem sind Investitionen in Gold- und Kryptowährungszertifikate ausschließlich im Rahmen klassischer Geldanlagen möglich."
      },
      investmentSettingsInfoBox: {
        okText: "Verstanden",
        title: "Wie geht smavesto mit ESG Kriterien um?"
      },

      onlySustainableCompanies: {
        title: "Informationen zur Nachhaltigkeit",
        okText: "Verstanden",
        cancelText: "Zurück",
        centered: true,
        width: 600
      }
    }
  },
  bankInformation: {
    tin: {
      label: "Steueridentifikationsnummer (TIN)",
      info: "Bitte geben Sie hier die Steuer-Identifikations-Nummer (TIN) Ihres Kindes an.",
      required: `Bitte geben Sie eine gültige TIN ein.`
    },
    taxLiability: {
      label:
        "Mein Kind unterliegt nicht der US-Steuerpflicht und ich/wir werde/n Smavesto jede Änderung unverzüglich mitteilen",
      info: `Geldanlegen bei Smavesto ist für alle Sparer möglich, die nicht der US-Steuerpflicht unterliegen. Sollte Ihr Kind in den USA Steuern zahlen, können Sie den Anmeldeprozess leider nicht weiter fortsetzen.`
    },
    raiseChurchRequest: {
      label:
        "Ich/Wir wünsche/n, dass die Baader Bank das Kirchensteuermerkmal meines/unseres Kindes im Rahmen der Depoteröffnung auf meine/unsere Veranlassung beim Bundeszentralamt für Steuern abruft.",
      info: `Bitte stimmen Sie ausdrücklich zu, dass die Baader Bank das Kirchensteuermerkmal Ihres Kindes beim Bundeszentralamt für Steuern einholt. So kann sichergestellt werden, dass die Kirchensteuer automatisch korrekt für Ihr Kind abgeführt wird, wenn dies für Ihr Kind verbindlich ist.`
    }
  },
  exemptionOrder: {
    title: "Freistellungsauftrag für Kapitalerträge für Ihr Kind",
    extraHintText:
      "Bitte beachten Sie: Für Kunden mit mehreren Stammnummern (Multistammkunden) bei der Baader Bank gilt der eingereichte Freistellungsauftrag für alle Konten, die sie bei der Baader Bank haben. Das bedeutet, dass über Smavesto eingereichte Freistellungsaufträge, die Kapitalerträge für Ihr Kind auch bei anderen Kooperationen von Steuern befreit.",
    subHintText:
      "Die in dem Auftrag enthaltenen Daten und freigestellten Beträge werden dem Bundeszentralamt für Steuern (BZSt) übermittelt. Sie dürfen zur Durchführung eines Verwaltungsverfahrens oder eines gerichtlichen Verfahrens in Steuersachen oder eines Strafverfahrens wegen einer Steuerstraftat oder eines Bußgeldverfahrens wegen einer Steuerordnungswidrigkeit verwendet sowie vom BZSt den Sozialleistungsträgern übermittelt werden, soweit dies zur Überprüfung des bei der Sozialleistung zu berücksichtigenden Einkommens oder Vermögens erforderlich ist (§ 45d EStG).Ich versichere, dass der Freistellungsauftrag meines Kindes zusammen mit Freistellungsaufträgen an andere Kreditinstitute, Bausparkassen, das BZSt usw. den für mein Kind geltenden Höchstbetrag von insgesamt 1000 € nicht übersteigt. Ich versichere außerdem, dass mein Kind mit allen für das Kalenderjahr erteilten Freistellungsaufträgen für keine höheren Kapitalerträge als insgesamt 1000 € im Kalenderjahr die Freistellung oder Erstattung von Kapitalertragsteuer in Anspruch nimmt. Die mit dem Freistellungsauftrag angeforderten Daten werden auf Grund von § 44a Absatz 2 und 2a,§ 45b Absatz 1 und § 45d Absatz 1 EStG erhoben. Die Angabe der steuerlichen Identifikationsnummer ist für die Übermittlung der Freistellungsdaten an das BZSt erforderlich. Die Rechtsgrundlagen für die Erhebung der Identifikationsnummer ergeben sich aus § 139a Absatz 1 Satz 1 2. Halbsatz AO, § 139b Absatz 2 AO und § 45d EStG. Die Identifikationsnummer darf nur für Zwecke des Besteuerungsverfahrens verwendet werden.",
    hintText: `Nach erfolgreicher Depoteröffnung haben Sie die Möglichkeit, einen Freistellungsauftrag für Ihr Kind in dem Depot Ihres Kindes anzulegen.\n\nMit einem Freistellungsauftrag können Sie sicherstellen, dass die Kapitalerträge bis zu einem bestimmten Betrag steuerfrei bleiben.`,
    label:
      "Möchten Sie einen Freistellungsauftrag für Kapitalerträge für Ihr Kind erteilen? *",
    amountInfo: `Hiermit erteile ich der Baader Bank AG den Auftrag, die für mein Kind bei Smavesto anfallenden Kapitalerträge vom Steuerabzug freizustellen und/oder bei
		Dividenden und ähnlichen Kapitalerträgen die Erstattung von Kapitalertragssteuer zu beantragen und versichere,
		dass die rechtlichen Voraussetzungen für den beantragten Freistellungsauftrag erfüllt sind.`,
    maxAmount: (
      value: string
    ) => `bis zur Höhe des für mein Kind geltenden Sparer-Pauschalbetrages von
            insgesamt ${value} EUR`,
    info: (maxAmount: string) => ({
      title: "Freistellungsauftrag für Kapitalerträge erteilen",
      text: `Wird ein Freistellungsauftrag (FSA) erteilt, können Kapitalerträge bis zur Höhe des Sparer-Pauschbetrags ohne Steuerabzug gutgeschrieben bzw. ausgezahlt werden. Die Freistellung ändert nichts daran, dass die Kapitalerträge grundsätzlich steuerpflichtig sind, soweit sie den Sparer- Pauschbetrag übersteigen. Bitte beachten Sie, dass die Summe Ihrer gestellten FSA nicht ${maxAmount} Euro pro Steuerpflichtigen übersteigt.`
    })
  },
  investmentSettingsModal: {
    investmentSettingsConfirm: {
      title: "Präferenzen ändern",
      content: (
        <>
          Die neuen Anlagepräferenzen Ihres Kindes werden mit der nächsten
          Reallokation
          <ReallocationTooltip /> berücksichtigt.
        </>
      ),
      okText: "Weiter",
      cancelText: "Abbrechen",
      centered: true,
      width: 600
    },

    investmentRiskConfirm: {
      title: "Anlagerisiko ändern",
      content: (
        <>
          Das neue Anlagerisiko Ihres Kindes wird mit der nächsten Reallokation
          <ReallocationTooltip />
          berücksichtigt.
        </>
      ),
      okText: "Weiter",
      cancelText: "Abbrechen",
      centered: true,
      width: 600
    },

    investmentRiskInfo: {
      title: "Anlagerisiko ändern",
      content: (
        <>
          Das neue Anlagerisiko Ihres Kindes wird mit der nächsten Reallokation
          <ReallocationTooltip />
          berücksichtigt.
        </>
      ),
      centered: true,
      width: 600
    },

    investmentSettingsInfo: {
      title: "Präferenzen ändern",
      content: (
        <>
          Die neuen Anlagepräferenzen Ihres Kindes werden mit der nächsten
          Reallokation
          <ReallocationTooltip /> berücksichtigt.
        </>
      ),
      centered: true,
      width: 600
    }
  }
};

export default onboardingUnderageLabels;
