import { Form, notification } from "antd";
import { useRouter } from "next/router";
import type { ParsedUrlQuery } from "querystring";
import { useState } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";
import { useResetStates } from "@states/all.states";
import { loggedInUserUriState } from "@states/auth.state";
import { loggedInUserOnboardingState } from "@states/onboarding.state";
import { summaryHistoryState } from "@states/summary.state";
import { DepotTypes } from "@utils/constants/DepotTypes";
import UNDERAGE_URL_PARAM from "@utils/constants/Urls";
import isLegitimated from "smavesto.core/lib/utils/typeguards/isLegitimated";

export const useIsUnderageMode = () => {
  const { query } = useRouter();

  return query.depotType === DepotTypes.UNDERAGE;
};

export const useOnboardingForm = () => {
  const [form] = Form.useForm();

  const { push, query } = useRouter();

  const [summaryHistory, setSummaryHistory] =
    useRecoilState(summaryHistoryState);

  const [isSubmit, setIsSubmit] = useState(false);

  const isUnderageMode = useIsUnderageMode();

  const [, onboarding] = useAsyncSelector(loggedInUserOnboardingState);

  const userIsLegitimated = onboarding ? isLegitimated(onboarding) : false;

  const getOnFinish =
    <T>(
      callback: (values: T, query?: ParsedUrlQuery) => Promise<string>,
      isLastStep = false
    ) =>
    async (values: T) => {
      if (typeof values !== "object") return;

      setIsSubmit(true);

      const nextPath = await callback(values, query);

      if (summaryHistory && !isLastStep) {
        push(summaryHistory + (isUnderageMode ? `?${UNDERAGE_URL_PARAM}` : ""));
        setSummaryHistory("");

        notification.success({
          message: "Die Angaben wurden gespeichert."
        });
      } else if (nextPath) {
        push(nextPath);
      }

      setIsSubmit(false);
    };

  return { form, getOnFinish, isSubmit, isUnderageMode, userIsLegitimated };
};

export const useLogout = () => {
  const [realUserUri] = useRecoilState(loggedInUserUriState);
  const resetUserUri = useResetRecoilState(loggedInUserUriState);
  const resetRecoilGlobal = useResetRecoilGlobal();

  const performLogout = async () => {
    if (realUserUri) {
      // user is logged in
      await smavestoCore.services.auth.logout(realUserUri);

      smavestoCore.endSession();
      resetRecoilGlobal();
      resetUserUri();
    }
  };

  return performLogout;
};

export const useSafeLogout = (ref?: string) => {
  const { push } = useRouter();

  return () =>
    push(`/safe-logout${ref ? `?ref=${encodeURIComponent(ref)}` : ""}`);
};

export const useResetRecoilGlobal = () => {
  const resetRecoilState = useResetStates();

  return resetRecoilState;
};
